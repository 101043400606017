@import '../../node_modules/modern-normalize/modern-normalize.css';

@import '../../node_modules/@fontsource/inter/index.css';

@import '../../node_modules/@fontsource/inter/400.css';

@import '../../node_modules/@fontsource/inter/500.css';

@import '../../node_modules/@fontsource/inter/700.css';

body {
  font-family: 'Inter';
}

::-moz-selection {
  background: black;
  color: white;
}

::selection {
  background: black;
  color: white;
}

.container {
  width: 100%;
  height: 100vh;
}
.container div {
  transition: opacity 3s ease-in-out;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.container div.--in {
  opacity: 1;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 10;
}
#bg1 {
  background-image: url('../img/bg1.jpg?as=webp');
}
#bg2 {
  background-image: url('../img/bg2.jpg?as=webp');
}
#bg3 {
  background-image: url('../img/bg3.jpg?as=webp');
}
#bg4 {
  background-image: url('../img/bg4.jpg?as=webp');
}
#bg5 {
  background-image: url('../img/bg5.jpg?as=webp');
}
#bg6 {
  background-image: url('../img/bg6.jpg?as=webp');
}
#bg7 {
  background-image: url('../img/bg7.jpg?as=webp');
}
