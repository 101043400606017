*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-cyrillic-ext-400-normal.a915bc85.woff2") format("woff2"), url("inter-all-400-normal.07103969.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-cyrillic-400-normal.c52df810.woff2") format("woff2"), url("inter-all-400-normal.07103969.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-greek-ext-400-normal.cbb7fc39.woff2") format("woff2"), url("inter-all-400-normal.07103969.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-greek-400-normal.6f009261.woff2") format("woff2"), url("inter-all-400-normal.07103969.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-vietnamese-400-normal.5230c6f5.woff2") format("woff2"), url("inter-all-400-normal.07103969.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-latin-ext-400-normal.520885f7.woff2") format("woff2"), url("inter-all-400-normal.07103969.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-latin-400-normal.cb07b618.woff2") format("woff2"), url("inter-all-400-normal.07103969.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-cyrillic-ext-400-normal.a915bc85.woff2") format("woff2"), url("inter-all-400-normal.07103969.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-cyrillic-400-normal.c52df810.woff2") format("woff2"), url("inter-all-400-normal.07103969.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-greek-ext-400-normal.cbb7fc39.woff2") format("woff2"), url("inter-all-400-normal.07103969.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-greek-400-normal.6f009261.woff2") format("woff2"), url("inter-all-400-normal.07103969.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-vietnamese-400-normal.5230c6f5.woff2") format("woff2"), url("inter-all-400-normal.07103969.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-latin-ext-400-normal.520885f7.woff2") format("woff2"), url("inter-all-400-normal.07103969.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("inter-latin-400-normal.cb07b618.woff2") format("woff2"), url("inter-all-400-normal.07103969.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("inter-cyrillic-ext-500-normal.8dba2790.woff2") format("woff2"), url("inter-all-500-normal.c003a7c4.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("inter-cyrillic-500-normal.4b56aa39.woff2") format("woff2"), url("inter-all-500-normal.c003a7c4.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("inter-greek-ext-500-normal.ac1e412e.woff2") format("woff2"), url("inter-all-500-normal.c003a7c4.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("inter-greek-500-normal.a15bf7ca.woff2") format("woff2"), url("inter-all-500-normal.c003a7c4.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("inter-vietnamese-500-normal.49724041.woff2") format("woff2"), url("inter-all-500-normal.c003a7c4.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("inter-latin-ext-500-normal.78095f4b.woff2") format("woff2"), url("inter-all-500-normal.c003a7c4.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("inter-latin-500-normal.48915fdf.woff2") format("woff2"), url("inter-all-500-normal.c003a7c4.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("inter-cyrillic-ext-700-normal.5a68cead.woff2") format("woff2"), url("inter-all-700-normal.1374c7c3.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("inter-cyrillic-700-normal.81be325a.woff2") format("woff2"), url("inter-all-700-normal.1374c7c3.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("inter-greek-ext-700-normal.dd164e0a.woff2") format("woff2"), url("inter-all-700-normal.1374c7c3.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("inter-greek-700-normal.f5052185.woff2") format("woff2"), url("inter-all-700-normal.1374c7c3.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("inter-vietnamese-700-normal.1963158b.woff2") format("woff2"), url("inter-all-700-normal.1374c7c3.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("inter-latin-ext-700-normal.f8c0de08.woff2") format("woff2"), url("inter-all-700-normal.1374c7c3.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("inter-latin-700-normal.561fd463.woff2") format("woff2"), url("inter-all-700-normal.1374c7c3.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
  font-family: Inter;
}

::selection {
  color: #fff;
  background: #000;
}

.container {
  width: 100%;
  height: 100vh;
}

.container div {
  opacity: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.container div.--in {
  opacity: 1;
}

.overlay {
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: #000000b3;
  position: relative;
}

#bg1 {
  background-image: url("bg1.4d0be7c8.webp");
}

#bg2 {
  background-image: url("bg2.d3709abf.webp");
}

#bg3 {
  background-image: url("bg3.02270f5e.webp");
}

#bg4 {
  background-image: url("bg4.d3ffe567.webp");
}

#bg5 {
  background-image: url("bg5.cdbedf09.webp");
}

#bg6 {
  background-image: url("bg6.bf0e7e8f.webp");
}

#bg7 {
  background-image: url("bg7.c04995b7.webp");
}

/*# sourceMappingURL=index.39af3799.css.map */
